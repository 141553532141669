module.exports = {
  "$id": "subAccount",
  "type": "object",
  "properties": {
    "username": {
      "title": "登录账号",
      "type": "string",
      "format": "loginName"
    },
    "name": {
      "title": "姓名",
      "type": "string"
    },
    "mobile": {
      "title": "手机号码",
      "type": "string",
      "format": "phone"
    },
    "password": {
      "title": "密码",
      "type": "string",
      "format": "password"
    }
  },
  "required": [
    "username",
    "name",
    "password"
  ],
  "errorMessage": {
    "username": {
      "message": "登录账号不符号规范",
      "required": "登录账号不能为空"
    },
    "name": {
      "message": "姓名不正确",
      "required": "姓名不能为空"
    },
    "mobile": {
      "message": "请输入正确的手机号",
      "required": "手机号不能为空"
    },
    "password": {
      "message": "密码长度为8-20位,必须包含字母和数字",
      "required": "密码不能为空"
    }
  }
}
