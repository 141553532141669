const birthdayValidate = require('./birthday')
const vcity = {
  11: '北京',
  12: '天津',
  13: '河北',
  14: '山西',
  15: '内蒙古',
  21: '辽宁',
  22: '吉林',
  23: '黑龙江',
  31: '上海',
  32: '江苏',
  33: '浙江',
  34: '安徽',
  35: '福建',
  36: '江西',
  37: '山东',
  41: '河南',
  42: '湖北',
  43: '湖南',
  44: '广东',
  45: '广西',
  46: '海南',
  50: '重庆',
  51: '四川',
  52: '贵州',
  53: '云南',
  54: '西藏',
  61: '陕西',
  62: '甘肃',
  63: '青海',
  64: '宁夏',
  65: '新疆',
  71: '台湾',
  81: '香港',
  82: '澳门',
  91: '国外'
}
const Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1] // 权值
const Ai = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'] // 校验结果匹配

// 身份证校验
module.exports = function (value) {
  // 校验长度
  if (!/^\d{17}(\d|X)$/.test(value)) {
    return {
      validate: false,
      message: '身份证号码只能是18位数字或者大写字母X'
    }
  }

  // 校验省份编码
  if (typeof vcity[value.substring(0, 2)] === 'undefined') {
    return {
      validate: false,
      message: '前两位号码省份校验出错，请输入正确的身份证号码'
    }
  }

  // 校验生日
  // if (value.length === 15) {
  //   const reFifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/
  //   const birthday = value.match(reFifteen)
  //   const validate = birthdayValidate([birthday[2], birthday[3], birthday[4]].join('-'))

  //   if (!validate.validate) {
  //     return {
  //       validate: false,
  //       message: '身份证中的出生日期不正确，请重新输入'
  //     }
  //   }
  // }

  if (value.length === 18) {
    const reEighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/
    const birthday = value.match(reEighteen)
    const validate = birthdayValidate([birthday[2], birthday[3], birthday[4]].join('-'))

    if (!validate.validate) {
      return {
        validate: false,
        message: '身份证中的出生日期不正确，请重新输入'
      }
    }

    // 18位身份证校验位校验
    if (value.charAt(17) === 'x') {
      value = value.replace('x', 'X')
    }

    const checkDigit = value.charAt(17)
    let cardNoSum = 0

    for (let i = 0, len = value.length - 1; i < len; i++) {
      cardNoSum = cardNoSum + value.charAt(i) * Wi[i]
    }

    const seq = cardNoSum % 11
    const getCheckDigit = Ai[seq]

    if (checkDigit.toString() !== getCheckDigit) {
      return {
        validate: false,
        message: '身份证校验位不正确，请重新输入'
      }
    }
  }

  return {
    validate: true,
    message: ''
  }
}
