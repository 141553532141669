import Ajv from 'ajv'
import addFormat from './format'
import localize from './localize'
import { isArray, forEach, isString, isNil, isObject, isEmpty } from 'lodash'

const ajv = new Ajv({
  $data: true,
  useDefaults: true,
  allErrors: true,
  jsonPointers: true,
  format: 'fast',
  strict: false,
  formats: {
    /* eslint-disable no-useless-escape */
    loginName: /^[a-zA-Z]\S{2,30}$/,
    password: /^\S{8,20}$/,
    telephone: /^((?:(?:\d{3}-?)?\d{8}|^(?:\d{4}-?)?\d{7,8})(?:-?\d+)?|(?:(?:\+|00)86)?1\d{10})$/,
    idCard: /^[0-9A-Za-z]{4,50}$/,
    token: /^[0-9]{6}$/,
    phone: /^1[3|4|5|6|7|8|9]\d{9}$/,
    captcha: /^[a-zA-Z0-9]{4}$/,
    countryCode: /^\d+$/,
    'date-time': /^\d\d\d\d-[0-1]\d-[0-3]\d[t\s](?:[0-2]\d:[0-5]\d:[0-5]\d|23:59:60)(?:\.\d+)?(?:z|[+-]\d\d:\d\d)?$/i
  }
})

addFormat(ajv)

export function validate (uri, data, path) {
  return new Promise(resolve => {
    const schema = require(`@/validate/${uri}`)
    const validate = ajv.compile(schema)

    // 移除无值结果，保证required校验有效
    removeEmptyValue(data)

    const valid = validate(data)

    if (!valid) {
      localize(validate.errors, ajv.errors || [], schema, ajv)
      const allErrors = parseErrors(validate.errors)

      if (allErrors[path]) {
        resolve({
          valid,
          error: allErrors[path].message
        })
      }

      resolve({
        valid
      })
    } else {
      resolve({
        valid
      })
    }
  })
}

function removeEmptyValue (model) {
  const isArr = isArray(model)

  forEach(model, (value, key) => {
    if (isNil(value) || (isString(value) && !value)) {
      isArr ? model.splice(key, 1) : delete model[key]
      return
    }

    if (isObject(value)) {
      if (isEmpty(value)) {
        delete model[key]
      } else {
        removeEmptyValue(value)
      }
    }
  })
}

function parseErrors (errors) {
  const map = {}

  errors.forEach(err => {
    map[err.path] = err
  })

  return map
}
