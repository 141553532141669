import name from './name'
import birthday from './birthday'
import idNo from './idNo'
import identityCard from './identityCard'
import username from './username'
import zhName from './zhName'
import enName from './enName'
// import mobile from './mobile'

export default {
  name,
  birthday,
  certNo: idNo,
  identityCard,
  username,
  zhLastName: zhName('lastName'),
  zhFirstName: zhName('firstName'),
  enName
  // mobile
}
