const regChinese = /^[\u2E80-\uFE4F]+$/
/* eslint-disable no-useless-escape */
const regSplit = /[\s\/]/g
const regEng = /^[a-zA-Z]+$/
/* eslint-disable no-useless-escape */
const regEngPassengerName = /^[a-zA-Z]+$/
// const regEngName = /^[a-zA-Z]+([a-zA-Z]+\s)*[a-zA-Z]+$/
const rarelyWord = ['毜', '屗', '曢', '朑', '毜', '毝', '毮', '烪', '焑', '燞', '癷', '稥', '聁', '艈', '蒊', '虲', '蝊', '袰', '鍂', '鐢', '闧', '霻', '兀', '嗀', '﨎', '﨏', '﨑', '﨓', '﨔', '礼', '﨟', '蘒', '﨡', '﨣', '﨤', '﨧', '﨨', '﨩', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']
const nameTypeLen = {
  ADT: 22,
  PWD: 22,
  MWD: 22,
  CHD: 20,
  INF: 12
}
const nameEnTypeLen = {
  ADT: 28,
  PWD: 28,
  MWD: 28,
  CHD: 24,
  INF: 18
}
const passengerTypeMap = {
  ADT: '成人',
  PWD: '成人',
  MWD: '成人',
  CHD: '儿童',
  INF: '婴儿'
}

// 姓名校验
// mustEn 是否必须英文，国际航线支持
// mustZH 是否必须中文
// type 乘客类型，判断长度，默认未成人
module.exports = function (value, mustEn = false, mustZH = false, type) {
  const len = value.length
  const isStrict = !!type // 只有乘机人支持生僻字 "中+英"
  let isEN = false
  let isZH = false
  type = type || 'ADT'
  let nameLen = nameTypeLen[type]
  const tempValue = value.replace(regSplit, '')
  let match

  // 全为英语
  if (regEng.test(tempValue)) {
    isEN = true
    nameLen = nameEnTypeLen[type]
  } else if (regChinese.test(tempValue)) {
    isZH = true
  }

  // 非乘机人，必须同中文或同英文
  if (!isEN && !isZH) {
    return {
      validate: false,
      message: '姓名必须同是中文或同是英文'
    }
  }

  // 国际乘机人规则
  if (mustEn && !isEN) {
    return {
      validate: false,
      message: '国际乘机人的姓名必须为英文，保持与护照一致!'
    }
  }

  // 联系人规则
  if (mustZH && !isZH) {
    return {
      validate: false,
      message: '联系人姓名只能为中文!'
    }
  }

  if (isEN) {
    // match = value.match(isStrict ? regEngPassengerName : regEngName)
    match = value.match(regEngPassengerName)

    // if (isStrict) {
    // if (!match || !match[1]) {
    //   return {
    //     validate: false,
    //     message: '姓名是英文或拼音时请遵循如下格式：Michael/Schumacher'
    //   }
    // }
    // } else {
    //   if (!match) {
    //     return {
    //       validate: false,
    //       message: '姓名是英文或拼音时，只允许英文加/'
    //     }
    //   }
    // }

    // 英文只做长度校验
    if (len > nameLen) {
      return {
        validate: false,
        message: `${isStrict ? passengerTypeMap[type] + '的' : ''}英文姓名长度不能超过${nameLen}位！`
      }
    }

    return {
      validate: true
    }
  }

  // 中文
  // 简单的只判断长度、生僻字；复杂的还要判断"中+英"
  if (isZH) {
    match = value.match(regChinese)

    // 全为中文
    if (!match) {
      return {
        validate: false,
        message: '中文姓名请省略特殊字符'
      }
    }

    // 先判断长度
    nameLen = nameLen / 2

    // if (len < 2) {
    //   return {
    //     validate: false,
    //     message: '中文姓名不能少于2个汉字'
    //   }
    // }

    if (len > nameLen) {
      return {
        validate: false,
        message: `${isStrict ? passengerTypeMap[type] + '的' : ''}中文姓名长度不能超过${nameLen}位！`
      }
    }

    // 判断是否有生僻字
    let word = ''
    let s
    for (let i = 0; i < len; i++) {
      s = value[i]

      if (rarelyWord.indexOf(s) > -1) {
        word = s
        break
      }
    }

    if (word) {
      return {
        validate: false,
        message: '姓名含有生僻字，请输入英文姓名或拼音'
      }
    }

    return {
      validate: true
    }
  }

  return {
    validate: true
  }
}
