module.exports = {
  "$id": "agentRegister",
  "type": "object",
  "title": "代理人更新",
  "properties": {
    "updateAgentUserInfo": {
      "title": "代理人更新",
      "type": "object",
      "properties": {
        "businessContact": {
          "title": "业务联系人",
          "type": "string"
        },
        "businessMobile": {
          "title": "业务联系电话",
          "type": "string",
          "format": "telephone"
        },
        "financeContact": {
          "title": "财务联系人",
          "type": "string"
        },
        "financeMobile": {
          "title": "财务联系电话",
          "type": "string",
          "format": "telephone"
        },
        "fltExpMobile": {
          "title": "航班不正常联系手机",
          "type": "string",
          "format": "phone"
        },
        "address": {
          "title": "公司地址",
          "type": "string"
        },
        "email": {
          "title": "邮箱",
          "type": "string",
          "format": "email"
        },
      },
      "required": [
        "businessContact",
        "businessMobile",
        "financeContact",
        // "financeMobile",
        "fltExpMobile",
        "address",
        "email"
      ]
    }
  },
  "required": [
    "updateAgentUserInfo"
  ],
  "errorMessage": {
    "updateAgentUserInfo.businessContact": {
      "required": "不能为空"
    },
    "updateAgentUserInfo.businessMobile": {
      "message": "联系方式格式错误",
      "required": "不能为空"
    },
    "updateAgentUserInfo.financeContact": {
      "required": "不能为空"
    },
    "updateAgentUserInfo.financeMobile": {
      "message": "联系方式格式错误",
      "required": "不能为空"
    },
    "updateAgentUserInfo.fltExpMobile": {
      "message": "手机格式错误",
      "required": "不能为空"
    },
    "updateAgentUserInfo.address": {
      "required": "不能为空"
    },
    "updateAgentUserInfo.email": {
      "message": "请输入正确的电子邮箱",
      "required": "电子邮箱不能为空"
    }
  }
}
