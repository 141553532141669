const identityCardValidate = require('./identityCard')

// const regIdNo = /^[0-9a-zA-Z]{4,50}$/
const regNo18 = /^[0-9a-zA-Z]{18}$/
const regNo15 = /^[0-9a-zA-Z]{1,15}$/
const regNo12 = /^[0-9a-zA-Z]{1,12}$/
// const regTN = /^[0-9]{3}-[0-9]{10}$/

module.exports = function (value, data) {
  const { certType } = data
  let valid = true
  let msg = ''

  if (certType === '5') {
    const { validate, message } = identityCardValidate(value)
    valid = validate
    msg = message
  } else if (certType === '207') {
    // 检验港澳台居住证
    if (!regNo18.test(value)) {
      msg = '请输入正确的18位港澳台居民居住证'
      valid = false
    }
  } else if (certType === '2' || certType === '206') {
    // 检验护照、台胞证
    if (!regNo15.test(value)) {
      msg = `请输入正确的${certType === '2' ? '国际护照' : '台胞证'}号码,仅支持英文、数字,长度1-15位！`
      valid = false
    }
  } else {
    const regIdNoValid = regNo12.test(value)

    if (!regIdNoValid) {
      msg = '仅支持输入英文及数字,长度1-12位！'
      valid = false
    }
  }

  return {
    validate: valid,
    message: msg
  }
}
