const moment = require('moment')
const bigMonth = [1, 3, 5, 7, 8, 10, 12]
const dateFormat = 'YYYY-MM-DD'

function getDate (month, year) {
  if (month === 2) {
    return ((year % 4 === 0) && (year % 100 !== 0 || year % 400 === 0)) ? 29 : 28
  } else if (bigMonth.indexOf(month) > -1) {
    return 31
  }

  return 30
}

function getTimeRange (type, departureDate) {
  let startDate
  let endDate

  if (type === 'ADT') {
    startDate = getPrevOrNextYear(departureDate, -70)
    endDate = getPrevOrNextYear(departureDate, -12)
  } else if (type === 'CNN' || type === 'CHD') {
    startDate = getPrevOrNextDate(getPrevOrNextYear(departureDate, -12), 1)
    endDate = getPrevOrNextYear(departureDate, -2)
  } else if (type === 'INF') {
    startDate = getPrevOrNextDate(getPrevOrNextYear(departureDate, -2), 1)
    endDate = getPrevOrNextDate(departureDate, -14)
  }

  return {
    startDate,
    endDate
  }
}

function getPrevOrNextYear (date, add) {
  return moment(date).add(add, 'y').format(dateFormat)
}

function getPrevOrNextDate (date, add) {
  return moment(date).add(add, 'd').format(dateFormat)
}

// 生日校验
module.exports = function (value, split = '-', type, departureDate, departureDate2) {
  const time = new Date(value.replace(new RegExp(split, 'g'), '/'))
  if (time.toString() === 'Invalid Date') {
    return {
      validate: false,
      message: '日期格式不正确'
    }
  }

  const year = time.getFullYear()
  const month = time.getMonth() + 1
  const date = time.getDate()

  if (year < 1900 || year > 3000) {
    return {
      validate: false,
      message: '年份超出范围'
    }
  }

  if (month < 1 || month > 12) {
    return {
      validate: false,
      message: '月份超出范围'
    }
  }

  if (date < 1 || date > getDate(month, year)) {
    return {
      validate: false,
      message: '日期超出范围'
    }
  }

  if (type) {
    if (type === 'PWD' || type === 'MWD') {
      type = 'ADT'
    }

    if (!departureDate) {
      departureDate = moment(new Date()).format(dateFormat)
    }
    const { startDate, endDate } = getTimeRange(type, departureDate)
    if (value < startDate || value > endDate) {
      if (type === 'ADT') {
        return {
          validate: false,
          message: '航班起飞当日,成人的年龄要大于等于12周岁,小于等于70周岁!'
        }
      } else if (type === 'CNN' || type === 'CHD') {
        return {
          validate: false,
          message: '航班起飞当日,儿童的年龄要大于等于2周岁,小于12周岁!'
        }
      } else if (type === 'INF') {
        return {
          validate: false,
          message: '航班起飞当日,婴儿的年龄要大于等于14天,小于2周岁!'
        }
      }
    }
    if (departureDate2) {
      const { startDate, endDate } = getTimeRange(type, departureDate2)
      if (value < startDate || value > endDate) {
        if (type === 'ADT') {
          return {
            validate: false,
            message: '航班起飞当日,成人的年龄要大于等于12周岁,小于等于70周岁!'
          }
        } else if (type === 'CNN' || type === 'CHD') {
          return {
            validate: false,
            message: '航班起飞当日,儿童的年龄要大于等于2周岁,小于12周岁!'
          }
        } else if (type === 'INF') {
          return {
            validate: false,
            message: '航班起飞当日,婴儿的年龄要大于等于14天,小于2周岁!'
          }
        }
      }
    }
  }

  return {
    validate: true,
    message: ''
  }
}
