module.exports = {
  "$id": "bookFlight",
  "type": "object",
  "title": "机票预订",
  "properties": {
    "seat": {
      "title": "座位数",
      "type": "string"
    },
    "name": {
      "title": "联系人",
      "type": "string"
    },
    "email": {
      "title": "联系邮箱",
      "type": "string",
      "format": "email"
    },
    "phone": {
      "title": "电话",
      "type": "string",
      "format": "telephone"
    },
    "mobile": {
      "title": "联系人手机",
      "type": "string",
      "format": "phone"
    },
    "intro": {
      "title": "说明",
      "type": "string"
    }
  },
  "required": [
    "seat",
    "name",
    "phone",
    // "email",
    "mobile"
  ],
  "errorMessage": {
    "seat": {
      "required": "座位数不能为空"
    },
    "name": {
      "required": "联系人不能为空"
    },
    "mobile": {
      "required": "联系人手机不能为空",
      "message": '手机格式错误'
    },
    "phone": {
      "required": "电话不能为空",
      "message": '电话格式错误'
    },
    "email": {
      "message": "请输入正确的电子邮箱"
    }
  }
}
