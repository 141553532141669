const regChinese = /^[\u2E80-\uFE4F]+$/

module.exports = function (type = 'lastName') {
  return function (value) {
    const len = value.length
    const maxLen = type === 'lastName' ? 6 : 16
    const name = type === 'lastName' ? '姓' : '名'

    if (!regChinese.test(value)) {
      return {
        validate: false,
        message: `请输入正确的中文${name}`
      }
    }

    if (len > maxLen) {
      return {
        validate: false,
        message: `中文${name}长度不能超过${maxLen}位`
      }
    }

    return {
      validate: true
    }
  }
}
