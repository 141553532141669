const regEmail = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/i
// const regPhone = /^1[3|4|5|6|7|8|9]\d{9}$/
const pattern = /^([A-Z]|[a-z]|[0-9])\w{4,38}([A-Z]|[a-z]|[0-9])$/

// 用户名
export default function (value) {
  value = value.trim()
  const len = value.length

  if (len > 40 || len < 6) {
    return {
      validate: false,
      message: '长度为6～40个字符'
    }
  }

  if (value.indexOf('@') > -1) {
    if (!regEmail.test(value)) {
      return {
        validate: false,
        message: '邮箱输入有误'
      }
    }

    return {
      validate: true
    }
  }

  if (/^\d+$/.test(value)) {
    // if (!regPhone.test(value)) {
    //   return {
    //     validate: false,
    //     message: '手机号码输入有误'
    //   }
    // }
    return {
      validate: true
    }
  }

  if (!pattern.test(value)) {
    return {
      validate: false,
      message: '输入格式有误'
    }
  }

  return {
    validate: true
  }
}
