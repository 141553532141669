module.exports = {
  "$id": "agentRegister",
  "type": "object",
  "title": "代理人注册",
  "properties": {
    "agree": {
      "title": "同意协议",
      "type": "boolean"
    },
    "registerAgentUserInfo": {
      "title": "代理人注册",
      "type": "object",
      "properties": {
        "company": {
          "title": "公司名称",
          "type": "string",
          'maxLength': 40
        },
        "iata": {
          "title": "航协号",
          "type": "string",
          'maxLength': 40
        },
        "office": {
          "title": "OFFICE号",
          "type": "string",
          'maxLength': 40
        },
        "cities": {
          "title": "城市",
          "type": "array"
        },
        "accountName": {
          "title": "银行开户名",
          "type": "string",
          'maxLength': 40
        },
        "license": {
          "title": "营业执照",
          "type": "string"
        },
        "bank": {
          "title": "开户银行",
          "type": "string",
          'maxLength': 40
        },
        "accountNumber": {
          "title": "银行账号",
          "type": "string",
          'maxLength': 40
        },
        "businessContact": {
          "title": "业务联系人",
          "type": "string",
          'maxLength': 40
        },
        "businessMobile": {
          "title": "业务联系电话",
          "type": "string",
          "format": "telephone"
        },
        "financeContact": {
          "title": "财务联系人",
          "type": "string",
          'maxLength': 40
        },
        "financeMobile": {
          "title": "财务联系电话",
          "type": "string",
          "format": "telephone"
        },
        "fltExpMobile": {
          "title": "航班不正常联系手机",
          "type": "string",
          "format": "phone"
        },
        "address": {
          "title": "公司地址",
          "type": "string",
          'maxLength': 80
        },
        "email": {
          "title": "邮箱",
          "type": "string",
          "format": "email",
          'maxLength': 40
        },
        "deptId": {
          "title": "所属销售大区",
          "type": "string"
        },
        "username": {
          "title": "用户名",
          "type": "string",
          "format": "loginName"
        },
        "password": {
          "title": "密码",
          "type": "string",
          "format": "password"
        },
        "confirmPassword": {
          "title": "确认密码",
          "type": "string",
          "format": "password",
          "const": {
            "$data": "1/password"
          }
        }
      },
      "required": [
        "company",
        // "iata",
        // "office",
        "cities",
        "accountName",
        "license",
        "bank",
        "accountNumber",
        "businessContact",
        "businessMobile",
        // "financeContact",
        // "financeMobile",
        "fltExpMobile",
        "address",
        // "email",
        "deptId",
        "username",
        "password",
        "confirmPassword"
      ]
    }
  },
  "required": [
    // "agree",
    "registerAgentUserInfo"
  ],
  "errorMessage": {
    "agree": {
      "required": "请先阅读并同意协议"
    },
    "registerAgentUserInfo.company": {
      "required": "不能为空"
    },
    "registerAgentUserInfo.iata": {
      "required": "不能为空"
    },
    "registerAgentUserInfo.office": {
      "required": "不能为空"
    },
    "registerAgentUserInfo.cities": {
      "required": "不能为空"
    },
    "registerAgentUserInfo.accountName": {
      "required": "不能为空"
    },
    "registerAgentUserInfo.license": {
      "required": "不能为空"
    },
    "registerAgentUserInfo.bank": {
      "required": "不能为空"
    },
    "registerAgentUserInfo.accountNumber": {
      "required": "不能为空"
    },
    "registerAgentUserInfo.businessContact": {
      "required": "不能为空"
    },
    "registerAgentUserInfo.businessMobile": {
      "message": "联系方式格式错误",
      "required": "不能为空"
    },
    "registerAgentUserInfo.financeContact": {
      "required": "不能为空"
    },
    "registerAgentUserInfo.financeMobile": {
      "message": "联系方式格式错误",
      "required": "不能为空"
    },
    "registerAgentUserInfo.fltExpMobile": {
      "message": "手机格式错误",
      "required": "不能为空"
    },
    "registerAgentUserInfo.address": {
      "required": "不能为空"
    },
    "registerAgentUserInfo.email": {
      "required": "不能为空"
    },
    "registerAgentUserInfo.deptId": {
      "required": "不能为空"
    },
    "registerAgentUserInfo.username": {
      "message": "用户名格式错误, 用户名必须以英文开头, 长度在3到30位",
      "required": "用户名不能为空",
    },
    "registerAgentUserInfo.password": {
      "message": "密码长度为8-20位,必须包含字母和数字",
      "required": "密码不能为空"
    },
    "registerAgentUserInfo.confirmPassword": {
      "message": "两次密码输入不一致！",
      "pattern": "请输入密码,8位-20位",
      "required": "请再次输入密码"
    },
    "registerAgentUserInfo.email": {
      "message": "请输入正确的电子邮箱",
      "required": "电子邮箱不能为空"
    }
  }
}
