module.exports = {
  "$id": "advice",
  "type": "object",
  "properties": {
    "title": {
      "title": "主题",
      "type": "string"
    },
    "advice": {
      "title": "建议内容",
      "type": "string"
    },
    "phone": {
      "title": "联系电话",
      "type": "string",
      "format": "phone"
    }
  },
  "required": [
    "title",
    "advice"
  ],
  "errorMessage": {
    "title": {
      // "message": "出入主题",
      "required": "请输入主题"
    },
    "advice": {
      "required": "请输入建议内容"
    }
  }
}
