module.exports = {
  "$id": "loginUser",
  "type": "object",
  "properties": {
    "username": {
      "title": "用户名",
      "type": "string"
    },
    "password": {
      "title": "密码",
      "type": "string"
    }
  },
  "required": [
    "username",
    "password"
  ],
  "errorMessage": {
    "username": {
      "message": "用户名不正确",
      "required": "用户名不能为空"
    },
    "password": {
      "message": "密码不正确",
      "required": "密码不能为空"
    }
  }
}
