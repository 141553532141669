// add format
import util from './utils'

export default ajv => {
  ajv.addKeyword('customValidate', {
    validate: function (schema, data, parentSchema, path, parentData, name, rootData) {
      const method = util[schema]

      if (!method) {
        console.error(`not found custom validate method: ${schema}`)
        throw new Error(`not found custom validate method: ${schema}`)
      }

      const { validate, message } = method(data, rootData, parentData)

      if (!validate) {
        const error = {
          keyword: 'customValidate',
          dataPath: path,
          message
        }
        this.errors ? this.errors.push(error) : this.errors = [error]
      }

      return validate
    }
  })
}
