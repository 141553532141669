module.exports = {
  "$id": "passwordChange",
  "type": "object",
  "title": "修改密码",
  "properties": {
    "oldPassword": {
      "title": "原始密码",
      "type": "string"
    },
    "password": {
      "title": "新密码",
      "type": "string",
      "format": "password"
    },
    "confirmPassword": {
      "title": "确认密码",
      "type": "string",
      "format": "password",
      "const": {
        "$data": "1/password"
      }
    }
  },
  "required": [
    "oldPassword",
    "password",
    "confirmPassword"
  ],
  "errorMessage": {
    "oldPassword": {
      "required": "原始密码不能为空"
    },
    "password": {
      "message": "请输入密码,8位-20位",
      "required": "新密码不能为空"
    },
    "confirmPassword": {
      "message": "两次密码输入不一致！",
      "pattern": "请输入密码,8位-20位",
      "required": "请再次输入密码"
    }
  }
}
